<template>
  <div>
    <div
      v-if="isLoaded"
      class="lead-details font-manrope"
    >
      <div class="d-flex">
        <b-img
          class="banner-image"
          :src="leadDetails.bannerUrl || defaultBanner"
          alt="Banner Image"
        />
      </div>

      <!-- details card -->
      <div class="detail-card">
        <div
          class="details-container custom-detail-container p-0 border-0 rounded-0"
        >
          <lead-preview-card :lead-details="leadDetails" />
        </div>
        <div class="ml-1 mt-1">
          <div class="detail-description">
            <h5 class="detail-heading">
              About the Lead
            </h5>
            <p class="detail-content">
              {{ leadDetails.aboutUs }}
            </p>
          </div>
          <div class="detail-description">
            <h5 class="detail-heading">
              Lead’s Brand Portfolio
            </h5>
            <p
              v-if="leadDetails.topBrands && leadDetails.topBrands.length"
              class="detail-content"
            >
              {{ leadDetails.topBrands.join(', ') }}
            </p>
            <p
              v-else
              class="detail-content text-center"
            >
              <strong>Portfolio Not Available</strong>
            </p>
          </div>
          <b-button
            class="mt-4 ml-1 mr-2 px-sm-3 font-weight-bolder"
            variant="outline-dark"
            @click="backToPreviousPage"
          >
            Back to previous page
          </b-button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="spinner-container"
    >
      <b-spinner label="Loading..." />
    </div>
  </div>
</template>

<script>
import { BButton, BImg, BSpinner } from 'bootstrap-vue'
import { apiToastWarning } from '@/@core/utils/toast'
import { FETCH_LEAD_DETAIL } from '@/store/modules/leadai.module'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import LeadPreviewCard from './LeadPreviewCard.vue'

export default {
  name: 'LeadAiDetail',
  components: {
    BImg,
    LeadPreviewCard,
    BSpinner,
    BButton,
  },
  data() {
    return {
      leadId: this.$route.params.leadId,
      leadDetails: {},
      isLoaded: false,
      defaultBanner: require('@/assets/images/banner/default-banner.jpg'),
      startTime: 0,
    }
  },
  created() {
    this.startTime = Date.now()
    this.fetchLeadDetails()
  },
  beforeDestroy() {
    const endTime = Date.now()
    const timeSpent = (endTime - this.startTime) / 1000 / 60
    analytics.track(constants.TRACKS.PAGES.BRAND_OPENS_LEAD_AI_DETAIL, {
      leadId: this.leadId,
      timeSpent: `${timeSpent} minutes`,
    })
  },
  methods: {
    fetchLeadDetails() {
     this.$store.dispatch(FETCH_LEAD_DETAIL, this.leadId)
        .then(res => {
          const lead = res.data.data
          this.leadDetails = lead
          this.isLoaded = true
          this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.map(
            item => {
              if (item.dynamic === true) {
                item.leadAIName = lead.storeName || 'New Lead'
              }
              return item
            },
          )
        })
        .catch(err => {
          console.error(err)
          apiToastWarning('Failed to load the lead details page. Please try again.')
          this.isLoaded = true
        })
    },
    backToPreviousPage() {
      analytics.track(
        constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_BACK_BUTTON,
      )
      localStorage.setItem('persistFilter', 'true')
      this.$router.back()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-brand-preview.scss';

.lead-details .banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.about-lead {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
}

.detail-descrption {
  margin-left: 10px;
  margin-top: 10px;
  display: block;
  width: 100%;
}
.detail-heading {
  font-family: Manrope;
  font-size: 23.67px;
  font-weight: 700;
  line-height: 35.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
}

.detail-content {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
}

.detail-description {
  margin: 10px 0;
  padding-left: 10px;
  width: 100%;
}
.custom-detail-container {
  min-width: 320px;
}
</style>
